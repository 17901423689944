'use strict';

Gri.module({
  name: 'footer-alpha',
  ieVersion: null,
  $el: $('.footer-alpha'),
  container: '.footer-alpha',
  fn: function () {
    
  }
});
